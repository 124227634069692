<template>
  <div class="import-records">
    <div class="tab">
      <div class="back" @click="goBack()">
        <i class="iconfont icon-fanhui"></i> <span>返回</span>
      </div>
      <div class="active"><i class="iconfont icon-kaoshi"></i> 导入记录</div>

    </div>
    <div class="table">
      <el-table :data="timuList" border style="width: 100%" size="small">
        <el-table-column prop="createDttm" label="导入时间"> </el-table-column>
        <el-table-column prop="context" label="题干" width="240"> </el-table-column>
        <el-table-column prop="type" label="题型">
          <template slot-scope="scope">
            <span>{{ scope.row.type == '1' ? '单选题' : scope.row.type == '2' ? '多选题' : '判断题' }}</span>
            
          </template>
        </el-table-column>
        <!-- <el-table-column prop="answer" label="答案">
          <template slot-scope="scope">
           <span>{{scope.row.answer=='T'?'正确':scope.row.answer=='F'?'错误':scope.row.answer}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="difficulty" label="难易度">
          <template slot-scope="scope">
            <span>{{ difficultyName[scope.row.difficulty] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="consistency" label="一致性">
          <template slot-scope="scope">
            <!-- <span>{{ difficultyName[scope.row.consistency] }}</span> -->
            <span>{{ scope.row.consistency }}</span>

          </template>
        </el-table-column>
        <el-table-column prop="level" label="等级">
          <template slot-scope="scope">
            <span>{{ scope.row.level }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="answer" label="答案">
          <template slot-scope="scope">
            <span>{{ scope.row.answer == 'T' ? '正确' : scope.row.answer == 'F' ? '错误' : scope.row.answer }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="knowledgeName" label="知识点"> </el-table-column>
        <el-table-column prop="knowledgePath" label="知识点编号"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
            <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <p style="text-align: center; margin-top: 20px" v-if="total > 0">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
        :page-sizes="[10, 20, 30, 50, 100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </p>


  </div>
</template>

<script>
export default {
  name: 'ImportRecords',
  data() {
    return {
      examId: null,
      type: null,
      total: 0,
      timuList: [],
      current: 1,
      size: 10,
      difficultyName: ['', '容易', '较易', '一般', '较难', '困难']

    };
  },
  components: {},
  created() {
    this.examId = this.$route.params.examId;
    this.getTimu();
  },
  methods: {
    async getTimu() {
      let data = {
        pageIndex: this.current,
        pageSize: this.size,
      };
      const result = await this.Api.databaseManage.searchQuestion(data);
      this.total = result.data.total;
      this.timuList = result.data.records;
      console.log("🚀 ~ getTimu ~ this.timuList:", this.timuList)
    },


    del(item) {
      this.$confirm('此操作将永久删除该题目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let result = await this.Api.databaseManage.deleteQuestion({ id: item.id })
        if (result.code != 200) {
          this.$message.error(result.message);
          return;
        }
        this.$message.success('删除成功');
        if(this.timuList.length == 1 && this.current > 1){
          this.current = this.current - 1;
        }
        this.getTimu();

      }).catch(() => { }); // 取消不做任何操作  
    },
    handleSizeChange(val) {
      this.size = val;
      this.current = 1;
      this.getTimu();
    },
    edit(item) {
      this.$router.push(`/admin/import-questions/${item.id}`);
    },
    handleCurrentChange(val) {
      console.log(`当前 ${val} 页`);
      this.current = val;
      this.getTimu();
    },
    goBack() {
      this.$router.push('/admin/database-manage');
    },
  },
};
</script>

<style scoped lang="scss">
.import-records {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  margin-top: 20px;
  overflow: hidden;
  border: 1px solid #d8d8d8;
  height: calc(100% - 20px);

  .tab {
    height: 48px;
    margin: 20px;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    position: relative;
    margin-left: 100px;

    div {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 30px;
      padding: 0 10px;
      cursor: pointer;
      bottom: -1px;
      box-sizing: border-box;

      i {
        margin-right: 3px;
      }
    }

    .active {
      border-bottom: 3px solid #3bafda;
      color: #3bafda;
    }

    .back {
      position: absolute;
      left: -80px;
      display: flex;
      align-items: center;
    }

    .reImport {
      position: absolute;
      right: 0;
    }
  }
}

::v-deep .el-form-item {
  margin-bottom: 20px;
}

.red {
  color: red;
}

.btn-padding {
  padding: 12px 20px;
}



::v-deep .table {
  margin: 0 20px;
  overflow: hidden;

  .el-table--small .el-table__cell {
    text-align: center;
  }

  .el-table__header tr th {
    background-color: #f2f2f2;
    color: #000;
    /* 设置表头单元格的背景色 */
  }
}
</style>
